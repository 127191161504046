<template>
  <UCard
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1 h-full"
    :ui="{
      body: {
        base: 'overflow-auto h-full',
      },
      footer: {
        base: 'sticky bottom-0 left-0 right-0 flex justify-end',
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader
        :allow-go-back="false"
        hash="modify"
        :title="title"
        data-test="settings-pack-header"
      />
    </template>
    <div>
      <UForm
        ref="settingsPackForm"
        :schema="schema"
        :state="formState"
        class="space-y-4"
        data-test="settings-pack-form"
        @submit="onSubmit"
      >
        <UFormGroup label="Name pack" name="name" class="mb-4" required>
          <UInput
            v-model="formState.name"
            data-test="settings-pack-name"
            placeholder="Name your settings pack"
            autofocus
            @keydown.enter.prevent="onSubmit"
          />
        </UFormGroup>
        <UFormGroup label="Description" name="description" class="mb-4">
          <UTextarea
            v-model="formState.description"
            placeholder="Short description to let your collaborators know how to use this pack..."
            :rows="5"
            data-test="settings-pack-description"
          />
        </UFormGroup>
      </UForm>
    </div>
    <div class="mt-4 space-y-3">
      <div
        v-for="settingItem in settingItems"
        :key="settingItem.name"
        class="flex items-center justify-between rounded-md border border-gray-200 px-4 py-3 cursor-pointer"
        :class="{ 'cursor-not-allowed opacity-50': !settingsPack.id }"
        :data-test="settingItem.dataTest"
        @click="settingItem.click"
      >
        <div class="flex items-center gap-3">
          <div
            class="p-1 rounded-md size-6 flex items-center justify-center"
            :class="settingItem.bgColor"
          >
            <component :is="settingItem.icon" />
          </div>
          <span class="text-sm font-medium">{{ settingItem.name }}</span>
        </div>
        <Icon size="16" name="heroicons:chevron-right-20-solid" />
      </div>
    </div>
    <div v-if="!settingsPack.id" data-test="settings-pack-note" class="mt-3 text-xs text-gray-500">
      Note: Please name & save your setting pack before customizing other
      fields.
    </div>
    <template #footer>
      <UButton color="gray" size="md" @click="closeSettings">
        Discard
      </UButton>
      <UButton
        color="green"
        size="md"
        class="ml-2"
        data-test="settings-pack-submit"
        :loading="isLoading"
        :disabled="formState.name.trim().length < 3"
        @click="onSubmit"
      >
        Save pack
      </UButton>
    </template>
  </UCard>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import type { FormSubmitEvent } from '#ui/types'
import { Icon } from '#components'
import TaskIcon from '#core/components/icon/Task.vue'
import StatusIcon from '#core/components/icon/Status.vue'
import type {
  CreateSettingsPackParams,
  UpdateSettingsPackParams,
} from '#settings-pack/types'

const route = useRoute()
const router = useRouter()
const toast = useToast()

const schema = z.object({
  name: z.string().trim().min(3).max(255),
})

const { currentSettingsPack, currentWorkspace } =
  useWorkspaceSharedState()

const { createSettingsPack } = useCreateSettingsPack()
const { updateSettingsPack } = useUpdateSettingsPack()

const formState = reactive({
  name: '',
  description: '',
  id: '',
})

const isLoading = ref(false)
const settingsPackForm = ref()

const settingsPack = computed(() => currentSettingsPack?.value)
const title = computed(() => {
  return settingsPack.value?.name ? `Edit: ${settingsPack.value?.name}` : 'Create new pack'
})

const { setSettingTab, closeSettings } = useBoardSettingsNavigator()

const settingItems = computed(() => {
  return [
    {
      name: 'Task types & Custom fields',
      bgColor: 'bg-blue-50',
      icon: TaskIcon,
      dataTest: 'settings-pack-task-types',
      click: () => settingsPack.value?.id && setSettingTab('tasks'),
    },
    {
      name: 'Statuses',
      bgColor: 'bg-yellow-50',
      icon: StatusIcon,
      dataTest: 'settings-pack-statuses',
      click: () => settingsPack.value?.id && setSettingTab('statuses'),
    },
    {
      name: 'Labels',
      bgColor: 'bg-green-50',
      icon: h(Icon, {
        name: 'heroicons:tag-solid',
        size: '18',
        class: 'text-green-500',
      }),
      dataTest: 'settings-pack-labels',
      click: () => settingsPack.value?.id && setSettingTab('labels'),
    },
  ]
})

const onSubmit = async (
  event: FormSubmitEvent<CreateSettingsPackParams | UpdateSettingsPackParams> | Event,
) => {
  isLoading.value = true
  let params: CreateSettingsPackParams | UpdateSettingsPackParams

  if ('data' in event) {
    params = { ...event.data }
  } else {
    params = { ...formState }
  }

  if (!params.name.trim() || params.name.trim().length < 3 || params.name.trim().length > 255) {
    isLoading.value = false
    return
  }

  if (!settingsPack.value?.id) {
    const data = await createSettingsPack({
      ...params,
      workspace: currentWorkspace.value.id,
    })
    if (data?.error) {
      return settingsPackForm.value.setErrors(
        parseGqlErrors(data.error.graphQLErrors)
      )
    }

    await router.push({ path: route.path, query: { id: data?.data?.settingsPack?.id } })

    toast.add({
      title: `Settings pack created`,
      description: `Your settings pack "${params.name}" was successfully created. Now you can assign this pack to boards.`,
    })
  } else {
    const data = await updateSettingsPack({
      ...params,
      id: settingsPack.value.id,
    })
    if (data?.error) {
      if (!parseGqlErrors(data.error.graphQLErrors).length) {
        return data.error.graphQLErrors.forEach((e) => {
          return toast.add({
            title: `Unable to update settings pack`,
            description: e.message,
            color: 'red',
          })
        })
      }

      return settingsPackForm.value.setErrors(
        parseGqlErrors(data.error.graphQLErrors)
      )
    }

    toast.add({
      title: `Settings pack updated`,
      description: `Your settings pack "${params.name}" was successfully updated.`,
    })
    closeSettings()
  }

  isLoading.value = false
}

watch(() => settingsPack.value, (newSettingsPack) => {
  if (newSettingsPack) {
    extend(formState, {
      name: newSettingsPack.name ?? '',
      description: newSettingsPack.description ?? '',
      id: newSettingsPack.id ?? '',
    })
  }
}, { immediate: true })
</script>
